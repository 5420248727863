import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"

import { GatsbySeo } from 'gatsby-plugin-next-seo';

import SMT from "../components/socialMediaTray"

import aboutStyles from "./styles/about.module.scss"

const projects = ({data}) => {

  var h = data.file.childImageSharp.fixed.height;
  var w = data.file.childImageSharp.fixed.width;


  return(
  <Layout>
    <GatsbySeo
        description="Simon Sekavčnik's personal blog."
        openGraph={{
          url:'https://www.simon.sekavcnik.com/about',
          title: "About",
          description: "About the Simon's Blogchain.",
        }}/>

    <div className={aboutStyles.statement}>
      <h1> About </h1>
      <hr />
    </div>
    <div className={aboutStyles.content}>
    <p>
    I am Simon, a masters student of Electrical and Computational Engineering 
    at Technical University of Munich.
    My interests range from open source code, 
    decentralized technologies, quantum computation and economics. 
    Full disclosure, I am Libertarian, maybe that will help you understand my anlge.
    </p>


    <SMT />

    <div 
      style={{height:h, width:w}}
      className={aboutStyles.imageWrapper}>
      <Img 
        className={"wrapper"}
        imgStyle={aboutStyles.content.image}
        fixed={data.file.childImageSharp.fixed} 
      />
    </div>
    </div>

  </Layout>
  )
}

export default projects

export const query = graphql`
  query {
    file(relativePath: { eq: "profile.jpg" }) {
      childImageSharp {
        fixed(width: 200, quality: 100) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`
